.footer {
  background-image: url("../../asset/Marketplace.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  background-blend-mode: overlay;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 60px 30px 60px;
  border-top: 2px solid #fdfdfc;
}
.footer__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer__left > div > h3 {
  font-family: "RMConnect";
  font-size: 22px;
  color: #1a1a1a;
  letter-spacing: 2px;
  font-weight: 600;
  margin-top: 19px;
}
.footer__left > div > p {
  font-family: "Cutive mono";
  font-size: 18px;
  color: #000000;
  letter-spacing: 2px;
  font-weight: 400;
}
.footer__logo {
  width: 90px;
  height: 70px;
  padding-right: 10px;
}
.footer__right {
  padding-top: 20px;
}

.footer__rightIcons {
  display: flex;
  margin-left: -20px;
}
.footer__rightPolygons > span {
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 24px;
  margin-top: -10px;
  margin-left: -10px;
}
.footer__icon {
  margin-right: 10px;
}
.footer__icon img {
  width: 28px;
}
.social__icon {
  color: #000000;
}
.social__icon:hover {
  opacity: 0.8;
}
.social__icon:active {
  color: #7f7d7a;
  transition: 0.1s;
}

.footer__rightPolygons {
  display: flex;
  align-items: center;
}

.footer__polygon {
  display: flex;
  width: 80px;
  height: 80px;
  position: relative;
  margin-left: -49px;
  margin-top: -10px;
}
.footer__polygon > span {
  top: 35%;
  left: 47%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
}

@media only screen and (max-width: 800px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px 40px 20px;
  }
  .footer__left > div > h3 {
    font-size: 16px;
  }
  .footer__left > div > p {
    font-size: 14px;
  }
  .footer__rightIcons {
    margin-left: 10px;
  }

  .footer__rightPolygons {
    margin-left: 30px;
  }
}
