.filter__button {
  width: auto;
  height: 37px;
  padding: 6px 20px;
  margin-right: 30px;
  border-radius: 25px;
  background-color: #ffffff;
  outline: none;
  border: none;
  color: #000000;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}
.filter__buttonActive {
  width: auto;
  height: 37px;
  padding: 0 20px;
  margin-right: 30px;
  border-radius: 25px;
  background-color: #626364;
  color: #ffffff;
  outline: none;
  border: none;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}

.primary__button {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  display: block;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  border: none;
  color: #000000;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}
.primary__button:hover,
.filter__button:hover {
  background-color: #626364;
  color: #ffffff;
}
.primary__button:active {
  transition: 0.4s;
  font-size: 13px;
}
@media only screen and (max-width: 1000px) {
  .filter__button {
    margin-bottom: 20px;
  }
  .filter__buttonActive {
    margin-bottom: 20px;
  }
}

/* -----POLYGON BUTTON------ */
.filter__cross,
.filter__crossActive {
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  color: #000000;
}
.filter__crossActive {
  color: #000000;
}

.polygon {
  cursor: pointer;
  display: flex;
  width: 70px;
  height: 65px;
  color: #eae5de;
  position: relative;
  resize: both;
  margin: -13px 0;
  margin-right: -15px;
}
.polygon > img {
  color: #eae5de;
}
.polygon > span {
  top: 37%;
  left: 45%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 15px;
}
