/* ----MODAL----- */
.modal {
  background-image: url("../../../asset/Marketplace.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5px;
  padding: 20px;
}
.model__closeIcon {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  margin-right: -10px;
}
.modal__card {
  width: 100%;
  /* height: 100%; */
  display: flex;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.04);
  background-color: #ffff;
}
.card__imageContainer {
  margin: 0;
  width: 100%;
  resize: both;
}
.card__image {
  width: 100%;
  /* height: 100%; */
  resize: both;
}
.modal__quantityBox {
  margin-top: 10%;
}
.purchase__modalbutton {
  display: flex;
  justify-content: flex-end;
  margin-top: 7%;
}
/* ----STATS CARD----- */
.stats__card {
  background-color: #f4f2ee;
  padding: 4% 5%;
  color: #000000;
  font-family: "Switzer";
  font-weight: bold;
  font-size: 16px;
}
.card__textList {
  margin-top: 4%;
}
.card__text {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 10px;
  padding: 1% 0;
}
.box__subText {
  display: flex;
  padding-left: 5px;
  display: inline;
  font-weight: 300;
  text-transform: capitalize;
}
@media only screen and (max-width: 800px) {
  .stats__card {
    margin-top: 7%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .stats__card {
    margin-top: 1% !important;
  }
  .card__textList {
    margin-top: 1%;
  }
  .modal__quantityBox {
    margin-top: 3%;
  }
  .purchase__modalbutton {
    margin-top: 2%;
  }
  .quantity__boxRight {
    padding: 2px 5px 2px 10px !important;
  }
  .icon__container {
    width: 35px !important;
  }
  .arrow__icon {
    width: 30px !important;
  }
}
/* ----POLYGON ICONS---- */
.artistLinksWrapper {
  display: flex;
  margin-top: 5%;
}
.artistLinksWrapper img {
  width: 18px;
}
.artistLinksWrapper div {
  padding: 0.4rem;
  margin: 0 0.4rem;
}
.artistLinksWrapper a {
  background-image: url("../../../asset/polygon-white.svg");
  background-position: 55% 52%;
}
.artistLinksWrapper span {
  color: #1a1a1a;
}
.polygon {
  cursor: pointer;
  display: flex;
  width: 70px;
  height: 70px;
  color: #eae5de;
  position: relative;
  resize: both;
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: -17px;
}
.polygon:hover {
  opacity: 0.8;
}
.polygon > img {
  color: #eae5de;
}
.icon {
  top: 40%;
  left: 43.5%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 22px;
}
.social__icon {
  color: #000000;
}
.social__icon img {
  width: 18px;
}
.social__icon:hover {
  opacity: 0.8;
}
.social__icon:active {
  color: #7f7d7a !important;
  transition: 0.1s !important;
}
/* ----QUANTITY--- */
.quantity__box {
  display: flex;
  align-items: center;
  font-family: "Switzer";
  font-weight: 500;
  color: #1a1a1a;
  font-size: 15px;
}
.quantity__boxRight {
  margin-left: 25px;

  padding: 5px 5px 10px 10px;

  width: 100%;
  background-color: #f4f2ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quantity__boxRight > div {
  display: flex;
}
.icon__container {
  width: 55px;
  height: auto;
}
.arrow__icon {
  width: 50px;
  cursor: pointer;
}
.icon__container > img:active {
  filter: grayscale(0%) invert(50%) sepia(0%) hue-rotate(180deg) saturate(9);
}
.purchase__cardReceiver {
  display: flex;
  align-items: center;
  font-family: "Switzer";
  font-weight: 500;
  color: #1a1a1a;
  font-size: 15px;
  text-transform: uppercase;
  width: 131px;
}

@media screen and (max-width: 1200px) {
  .artistLinksWrapper {
    margin-top: 5px;
  }
}
