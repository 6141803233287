.navbar {
  background-image: url("../../asset/Marketplace.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 18px 40px 18px 40px;
  border-bottom: 2px solid #fdfdfc;
  margin: 0;
}
.toggle {
  border: none;
  font-size: 18px;
  margin-top: -4px;
  color: #1a1a1a;
  outline: none;
}
.navbar__brand {
  margin-top: 10px;
  font-family: "RMConnect";
  font-size: 36px;
  font-style: normal;
  color: #1a1a1a;
  letter-spacing: 1.5px;
  font-weight: 600;
}
.navbar__logo {
  width: 80px;
  height: 68px;
  margin-right: 10px;
  resize: both;
}
.navbar__link {
  font-family: "Switzer";
  font-weight: bold;
  letter-spacing: 2px;
  color: #1a1a1a;
  font-size: 18px;
  margin: 0 12px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
}
.navbar__link:hover {
  color: #7f7d7a !important;
}

.navbar__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  transition: 0.3s all ease-in-out;
}
.navbar__icon img {
  filter: invert(100%);
}
.navbar__icon:hover {
  filter: invert(30%);
}
.connect__button {
  font-family: "Switzer";
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 18px;
  background-color: white;
  border: 3px solid #000000;
  border-radius: 5px;
  color: #1a1a1a;
  width: 140px;
  height: 75px;
  text-align: center;
  margin-left: 25px;
}

.connect__button:hover {
  color: #7f7d7a !important;
}

.connect__button:active {
  font-size: 15px;
  transition: 0.4s;
}
.social__icon {
  color: #000000;
}
.social__icon:active {
  border: none !important;
}
.social__icon:hover {
  border-radius: 10px;
  padding: 3px;
  border: 3px solid black;
}
@media only screen and (max-width: 1200px) {
  .navbar {
    padding: 15px;
  }
  .navbar__brand {
    font-size: 20px;
  }
  .navbar__logo {
    width: 60px;
    height: 50px;
  }
  .navbar__icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1075px) {
  .navbar__link {
    font-size: 14px;
    margin: 0;
  }
}
@media only screen and (max-width: 400px) {
  .navbar__brand {
    font-size: 15px;
  }
  .navbar__logo {
    width: 40px;
    height: 35px;
    margin-right: 5px;
  }
  .connect__button {
    margin-left: 0;
  }
}
