.polygon {
  cursor: pointer;
  display: flex;
  width: 100px;
  height: 95px;
  color: #eae5de;
  position: relative;
  resize: both;
  margin-top: -10px;
}
.polygon > img {
  color: #eae5de;
}
.polygon > span {
  top: 36%;
  left: 46%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 22px;
}
.span {
  top: 36%;
  left: 46%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 22px;
}
.span:active {
  top: 38%;
  left: 47%;
  font-size: 18px;
}
