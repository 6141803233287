.marketplace {
  background-image: url("../../asset/Marketplace.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}

.available__cardsContainer {
  padding-top: 70px;
  padding-bottom: 100px;
}

.filter__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding: 10px;
  position: relative;
}

.filter__buttons button:last-child {
  margin-right: 0;
}

.cards__container {
  max-height: 470px;
  overflow-y: scroll;
}

.cards__subContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttons__container {
  padding-top: 20px;
  border-radius: 0;
}

.button__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchaseCol {
  display: flex;
  justify-content: flex-end;
}
.warning {
  display: none;
}
@media only screen and (max-width: 991px) {
  .filter__buttons div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .purchaseCol button {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 800px) {
  .card__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .cards__container {
    display: flex;
    justify-content: center;
    height: auto;
    overflow-x: hidden;
  }

  .filter__buttons {
    display: flex;
    justify-content: flex-start;
  }

  .buttons__container {
    flex-direction: column;
    padding-top: 30px;
  }

  .button__container {
    margin-bottom: 20px;
  }
}

.infoIcon{
  width: 24px;
}
