.purchasedCard__image {
  width: 270px;
  resize: both;
  display: flex;
  flex-direction: row;
  margin: 15px 15px;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 6px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  outline: none;
}
.availableCard__image {
  width: 330px;
  resize: both;
  display: flex;
  flex-direction: row;
  margin: 15px 15px;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  outline: none;
}
.availableCard__image:focus,
.purchasedCard__image:focus {
  -webkit-box-shadow: 0 0 0 8px #626364;
  box-shadow: 0 0 0 9px #626364;
  border-radius: 3px;
  outline: none;
}
@media only screen and (max-width: 600px) {
  .availableCard__image {
    width: 250px;
  }
}
@media only screen and (min-width: 1800px) {
  .purchasedCard__image {
    width: 60%;
  }
}
