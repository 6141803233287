.modal {
  background-image: url("../../../asset/Marketplace.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5px;
  min-height: 300px;
  padding: 50px 12px 12px 12px;
}
.modal__closeIcon {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -14px;
  right: -28px;
}
.modal__card {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.04);
  background-color: #ffff;
}
.modal__right {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 4%;
}
.card__body {
  background-color: #ffffff;
  width: 50%;
  padding: 0 3% 2% 3%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__body > div {
  text-align: center;
}
.card__body > div > .heading {
  color: #000000;
  font-family: "Cutive Mono";
  font-weight: 700;
  font-style: normal;
  font-size: 60px;
}
.card__body > div > .para {
  color: #000000;
  font-family: "Switzer";
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 30px;
}
.card__imageContainer {
  margin: 0;
  width: 100%;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__spinnerContainer {
  margin: 0;
  width: 100%;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card__image {
  margin-right: -10px;
  resize: both;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1000px) {
  .card__body > div > .heading {
    font-size: 25px;
  }
  .card__body > div > .para {
    font-size: 12px;
    line-height: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .card__body > div > .heading {
    padding-top: 10px;
    font-size: 15px;
  }
  .card__body > div > .para {
    font-size: 8px;
    line-height: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .modal__right {
    padding-top: 4%;
    padding-bottom: 0;
  }
  .model__closeIcon {
    position: relative;
    margin: 0;
    margin: -30px 0;
  }
}
