.purchased__cardsContainer {
  background-color: #f4f2ee;
  padding: 25px 30px 0 20px;
  max-height: 810px;
  resize: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
.card__list {
  display: flex;
  justify-content: center;
}
.cards__Header {
  display: flex;
  color: #000000;
  font-family: "Switzer";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  align-items: center;
  justify-content: space-around;
}
.cards__Header > .heading {
  cursor: pointer;
}
