#card__editor {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-image: url("../../asset/mainbg.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-size: 100% 100%;
}
.card__editorLeft {
  padding: 60px 30px 60px 120px;
}
.card__editorRight {
  background-color: #f4f2ee;
  padding-bottom: 60px;
}
.editor__leftBottom {
  padding-top: 30px;
}
.left__BottomInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  font-family: "Switzer";
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 25px;
}
.left__BottomInput > input {
  width: 100%;
  background-color: #f4f2ee;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 17px 10px;
  text-align: end;
}
.left__BottomInput > input::placeholder {
  text-align: end;
  font-style: italic;
  color: #000000;
  opacity: 0.37;
}
.left__BottomInput > input:focus::placeholder {
  color: transparent;
}
.bottom__primaryButtons {
  display: flex;
  justify-content: flex-end;
}
.bottom__buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom__buttonGroup > .share__btn {
  width: 80px;
  padding: 0;
  border: none;
  font-size: 15px;
  line-height: 22px;
  background-color: transparent;
  color: #000000;
  font-family: "Switzer";
  font-weight: 400;
  margin-right: 20px;
  margin-left: -30px;
}
@media only screen and (max-width: 801px) {
  .card__editorLeft {
    padding: 30px;
  }
  .left__BottomInput {
    margin-top: 25px;
  }
}
