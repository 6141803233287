.five_modal {
  resize: both;
  width: 570px;
  left: 50% !important;
  top: 40px !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.five_wrapper {
  background: url("./../../asset/bluebg.jpg");
  background-color: #b4deff;
  position: relative;
  border-color: white;
  border-width: 4px;
  border-style: solid;
  border-radius: 6px;
}
.five_wrapper::before {
  content: " ";
  position: absolute;
  left: -21px;
  top: 15px;
  border-top: 20px solid transparent;
  border-right: 20px solid white;
  border-left: none;
  border-bottom: 20px solid transparent;
}
.five_wrapper::after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 20px;
  border-top: 15px solid transparent;
  border-right: 15px solid #b4deff;
  border-left: none;
  border-bottom: 15px solid transparent;
}

.subtext {
  margin-top: 7px;
  margin-left: 10px;
}

.mb_60 {
  margin-bottom: 60px;
}

.first_dot {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.last_dot {
  background: #0f7dea;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.raised_box {
  background: #b4deff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  margin-top: 10px;
  width: 530px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  padding: 0;
}

.dot {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.close_icon {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .five_modal {
    width: 450px !important;
    left: 20px !important;
  }

  .subtext {
    width: 300px !important;
  }
  .raised_box {
    width: 390px;
    height: 230px;
  }
}
