.one_modal {
  width: 550px;
  left: 40px !important;
  top: -20px;
}

.wrapper {
  background: url("./../../asset/bluebg.jpg");
  background-color: #b4deff;
  position: relative;
  border-color: white;
  border-width: 4px;
  border-style: solid;
  border-radius: 6px;
}

.watermark {
  display: none;
  width: 240px;
  height: 300px;
  position: absolute;
  top: -10px;
  right: 0px;
  bottom: -30px;
  rotate: -50deg;
  opacity: 0.6;
  /* z-index: 1; */
}

.title {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 40px;
}

.subtext {
  margin-top: 10px;
  margin-left: 10px;
}

.mb_60 {
  margin-bottom: 60px;
}

.first_dot {
  background: #0f7dea;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.second_dot {
  background: #c3e5ff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.dot {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.close_icon {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .one_modal {
    width: 420px;
    left: 70px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .one_modal {
    left: 50px !important;
  }
}

@media only screen and (max-width: 550px) {
  .one_modal {
    width: 390px;
  }

  .title {
    width: 300px;
  }
}

@media only screen and (max-width: 460px) {
  .one_modal {
    width: 320px;
  }
}
