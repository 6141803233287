@font-face {
  font-family: "Cutive Mono";
  src: local("Cutive Mono"),
    url("./fonts/CutiveMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Switzer";
  src: local("Switzer"), url("./fonts/Switzer-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Switzer-Bold";
  src: local("Switzer-Bold"), url("./fonts/Switzer-Bold.otf") format("opentype");
}
@font-face {
  font-family: "RMConnect";
  src: local("RMConnect"),
    url("./fonts/RMConnect-Regular.otf") format("opentype");
}
.round-borders {
  box-shadow: 0 0 0 9px #626364 !important;
  border-radius: 3px !important;
  outline: none !important;
}
@media (max-width: 768px) {
  .mb-only-sm-2 {
    margin-bottom: 0.6rem;
  }
}
@media (max-width: 1199px) {
  .mb-below-xl-2 {
    margin-bottom: 0.6rem;
  }
}
