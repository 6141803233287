.filter__button {
  min-width: max-content;
  width: auto;
  height: 37px;
  padding: 6px 20px;
  margin-right: 30px;
  border-radius: 25px;
  background-color: #ffffff;
  outline: none;
  border: none;
  color: #000000;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}
.filter__buttonActive {
  min-width: max-content;
  width: auto;
  height: 37px;
  padding: 0 20px;
  margin-right: 30px;
  border-radius: 25px;
  background-color: #626364;
  color: #ffffff;
  outline: none;
  border: none;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}

.primary__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  display: block;
  min-width: 140px;
  padding: 6px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  border: none;
  color: #000000;
  font-family: "Switzer";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  transition: 0.3s;
}
.primary__button:hover,
.filter__button:hover {
  background-color: #626364;
  color: #ffffff;
}
.primary__button:active {
  transition: 0.4s;
  font-size: 13px;
}
@media only screen and (max-width: 1000px) {
  .filter__button {
    margin-bottom: 20px;
  }
  .filter__buttonActive {
    margin-bottom: 20px;
  }
}

/* -----POLYGON BUTTON------ */
.filter__cross,
.filter__crossActive {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #000000;
}
.filter__crossActive {
  color: #000000;
}

.polygon {
  cursor: pointer;
  display: flex;
  width: 70px;
  height: 65px;
  color: #eae5de;
  position: relative;
  resize: both;
  margin: -13px 0;
  margin-right: -15px;
}
.polygon > img {
  color: #eae5de;
}
.polygon > span {
  top: 37%;
  left: 45%;
  position: absolute;
  font-family: "Switzer";
  font-weight: bold;
  color: #1a1a1a;
  font-size: 15px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  width: 70%;
  margin-bottom: -5px;
  height: 60%;
  background-color: transparent;
  transition: 0.2s all ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="checkbox"]:checked:before {
  position: absolute;
  content: "X";
  z-index: 1;
  top: 58%;
  left: 55%;
  font-family: "Switzer";
  font-weight: 700;
  color: #1a1a1a;
  font-size: 15px;
  transform: translate(-50%, -50%);
}
