.stats__card {
  background-color: #f4f2ee;
  padding: 4% 5%;
  color: #000000;
  font-family: "Switzer";
  font-weight: 300;
  font-size: 17px;
}
.card__boxList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.5%;
}
.card__box {
  text-align: center;
  border: 2px solid #ffffff;
  width: 120px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 10px;
  margin-bottom: 2.5%;
  padding: 2% 4px;
}
.box__subText {
  display: block;
  font-weight: 300;
}

.price {
  background: white;
  border-color: #000;
}
