.two_modal {
  width: 550px !important;
  top: 100px;
  right: -20%;
}

.wrapper {
  background: url("./../../asset/bluebg.jpg");
  background-color: #b4deff;
  position: relative;
  border-color: white;
  border-width: 4px;
  border-style: solid;
  border-radius: 6px;
}
.wrapper:before {
  content: " ";
  position: absolute;
  right: 25px;
  top: -21px;
  border-top: white;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid white;
}
.wrapper:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: white;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #b4deff;
}

.watermark {
  display: none;
  width: 240px;
  height: 300px;
  position: absolute;
  top: -15px;
  right: 10px;
  bottom: -90px;
  rotate: -50deg;
  /* z-index: 1; */
  opacity: 0.6;
}

.title {
  margin-top: 10px;
  margin-left: 10px;
}

.subtext {
  margin-top: 10px;
  margin-left: 10px;
}

.m90 {
  margin-bottom: 120px;
}

.first_dot {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.second_dot {
  background: #0f7dea;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.dot {
  background: #fff;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-left: 5px;
  cursor: pointer;
}

.close_icon {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1;
}

@media only screen and (max-width: 460px) {
  .two_modal {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .two_modal {
    width: 430px !important;
    left: 5% !important;
  }
  .title {
    width: 270px;
  }
}
