.selected__cardContainer {
  resize: both;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.card__overlay {
  position: relative;
}
.card__overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  /* background: rgba(0,0,0,0.5); */
}
.selected__card {
  width: 100%;
  display: flex;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 8px 9px 1px rgba(0, 0, 0, 0.04);
}
.card__body {
  width: 50%;
  padding: 0 3% 2% 3%;
  margin: 0;
}
.card__bodyLogo {
  display: flex;
  justify-content: flex-end;
  padding-top: 8%;
  margin-bottom: 7%;
  border: 1px solid red;
  opacity: 0;
}
.card__imageContainer {
  margin: 0;
  width: 50%;
}
.card__image {
  width: 100%;
  height: 100%;
}
.card__bodyLogo > img {
  width: 30%;
  height: 30%;
}
.card__text {
  font-family: "Cutive Mono";
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 1.5px;
  color: #000000;
  background: transparent;
  white-space: break-spaces;
  width: 505px;
  height: 475px;
  max-height: 480px;
  border: none;
  outline: none;
  resize: none !important;
  overflow: hidden !important;
  overflow-x: hidden;
}
.card__text:focus::placeholder {
  color: transparent;
}
.card__artistNameAndSupply {
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  text-transform: uppercase;
  font-family: "Switzer";
  letter-spacing: 0.3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.card__artistNameAndSupply .card__ethPrice,
.card__artistNameAndSupply .card__cardPrice {
  display: none;
}
.card__overlay:hover .card__ethPrice,
.card__overlay:hover .card__cardPrice {
  display: block;
}
.card__overlay:hover .card__artistName,
.card__overlay:hover .card__supply {
  display: none;
}
.card__textContainer {
  resize: none;
  margin: 0;
  padding: 0;
  font-size: 22px;
  overflow: hidden;
}
.polygon {
  cursor: pointer;
  display: flex;
  position: relative;
  resize: both;
  margin-top: -10px;
}
.polygon > img {
  width: 130px;
  height: 115px;
  filter: grayscale(0%) invert(5%) sepia(0%) hue-rotate(180deg) saturate(9);
}
.polygon > span {
  top: 52.5%;
  left: 52%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: "Switzer";
  color: #e8e8f1;
  font-size: 20px;
}

.count {
  margin-top: -80px;
  margin-right: -30px;
  display: flex;
  justify-content: flex-end;
  /* position: absolute; */
}
.count > div > span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
